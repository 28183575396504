<template>
  <div>
    <hero-global title="Cómo asociarse"
                 subtitle="Cómo asociarse al CERMI"
                 breadcrumb="Cómo asociarse al CERMI"
                 img="/img/how-to-join-cermi/how-to-join-cermi.svg" first-width="70" last-width="30" />
  </div>
  <div class="container p-x-3-rem">
    <section>
      <article>
        <div class="floating">
          <figure>
            <img class="w-100" alt="Imagen CERMI" src="/img/how-to-join-cermi/how-to-join-cermi.png" />
          </figure>
          <div class="p-1-rem">
            <h4 class="text-primary font-family-roboto font-size-16">Las Entidades de base asociativa interesadas en incorporarse al CERMI Estatal, en calidad de socios, deberán formular petición de ingreso en tal sentido con arreglo a las siguientes indicaciones:</h4>
            <br/>
            <br/>
            <ol>
              <li class="font-family-roboto font-size-16 w-40">Escrito formal solicitando el ingreso en el CERMI Estatal.</li>
              <li class="font-family-roboto font-size-16 w-40">Copia de los estatutos oficiales de la Entidad aspirante debidamente inscritos en el Registro Oficial correspondiente.</li>
              <li class="font-family-roboto font-size-16 w-40">Copia de la Resolución administrativa por la que se acuerda la inscripción de la Entidad en el Registro Administrativo correspondiente, con expresión del número de inscripción.
                Copia del CIF de la Entidad.</li>
              <li class="font-family-roboto font-size-16 w-40">Declaración de no formar parte de ninguna Organización que ya sea miembro de CERMI Estatal.</li>
              <li class="font-family-roboto font-size-16">Relación de nominal de entidades (personas jurídicas) que forman parte de la Entidad aspirante con expresión de su razón social (dirección postal) y CIF.</li>
              <li class="font-family-roboto font-size-16">Declaración responsable del número de personas físicas que forman parte de las Asociaciones y Federaciones que componen la Entidad aspirante, y en su caso, relación nominal, siempre con arreglo a lo establecido en la legislación de protección de datos de carácter personal.</li>
              <li class="font-family-roboto font-size-16">Memoria de Actividades de la Entidad del último año o período desde el que se haya constituido, si este es menor.</li>
              <li class="font-family-roboto font-size-16">Escrito expresando su adhesión a los principios, criterios de actuación y valores del CERMI Estatal.</li>
              <li class="font-family-roboto font-size-16">Declaración responsable de que la Entidad aspirante no promueve, despliega, coopera, ampara, suscribe o justifica de ningún modo la realización de actividades ilícitas que comporten el uso impropio de las personas con discapacidad o de su imagen social.</li>
              <li class="font-family-roboto font-size-16">La documentación se aportará en la Secretaría General del CERMI Estatal.</li>
              <li class="font-family-roboto font-size-16">Respecto a los requisitos de ingreso, vienen establecidos en los Estatutos del CERMI, que pueden consultarse en esta página de internet.</li>
              <li class="font-family-roboto font-size-16"><p>Respecto de las organizaciones de base de salud o de pacientes, que aspiren a ingresar en el CERMI Estatal, el Comité Ejecutivo de la Entidad ha establecido una serie de criterios <a href="/pdf/requisitos.pdf" target="_blank" style="text-decoration: none;color: #0069B4;cursor: pointer;">(ver documento adjunto)</a> que informarán la admisión, que han de ser tenidos en cuenta.</p></li>
              <li class="font-family-roboto font-size-16">Correo electrónico:  CERMI@CERMI.es</li>
            </ol>
          </div>
        </div>
      </article>
    </section>
    <section><carrousel2 /></section>
    <other-links-interest class="links-interest-box container"/>
    <br/>
    <br/>
  </div>
</template>

<script>
import HeroGlobal from "@/components/Global/HeroGlobal";
import Carrousel2 from "@/components/Global/Carrousel2";
import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
export default {
  name: "HowToJoinCermi",
  components: {OtherLinksInterest, Carrousel2, HeroGlobal}
}
</script>

<style scoped lang="scss">
@import "../styles/colours";
figure {
  margin: 0;
}

img {
  max-width : 100%;
  height    : auto;
}

article {
  max-width : 1440px;
  margin    : auto;
  display   : grid;
  grid-column: auto;
}

article > * {
  grid-column: main;
}

.floating figure {
  float        :right;
  max-width    : 50%;
  margin-right : 20px;
}

.floating p {
  margin-top:0;
}

ol {
  list-style    : none;
  counter-reset : counter;
  li {
    counter-increment : counter;
    display           : flex;
    align-items       : center;
    width             : 80%;
  }
  li::before {
    content          : counter(counter) " ";
    color            : $blueLight;
    font-weight      : bold;
    font-size        : 48px;
    padding          : .5rem;
    text-align: right;
  }
}

</style>
